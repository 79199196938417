<template>
    <div>
        <Title heading="Payment" />
        <div v-if="!seenMessage && !iframeError">
            <img class="pay-loading-spinner" alt="spinner" :src="require('@/assets/spinner-black.png')" />
            <span class="pay-loading-text">
                Loading Secure Payment Connection
            </span>
        </div>
        <div v-if="iframeError">
            Sorry, the payment system is unavailable. Please try again later.
        </div>
        <div class="pay-iframe-container">
            <iframe v-if="iframeSrc" :src="iframeSrc" ref="payIframe" class="payment-iframe"></iframe>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import Title from '@/components/Common/Title';
import constants from '@/constants';

export default {
    name: 'Review',
    components: { Title },
    data() {
        return {
            iframeSrc: null,
            seenMessage: false,
            iframeError: false
        };
    },
    computed: {
        ...mapGetters(['getApplicant', 'account', 'rateQuote']),
        ...mapActions(['submitNewPolicy']),
        accountNumber() {
            return this.account.accountNumber;
        },
        totalAmount() {
            let total = 0;
            if (this.rateQuote) {
                total += this.rateQuote.TotalAnnualPremium || 0;
                total += this.rateQuote.TotalAnnualTaxesAndSurcharges || 0;
            }
            return total;
        }
    },
    async mounted() {
        if (!this.getApplicant || !this.getApplicant.gender || !this.totalAmount) {
            this.$router.replace('/');
            return;
        }

        window.scrollTo(0, 0);
        window.addEventListener('message', this.iframeEventHandler);

        if (!this.accountNumber) {
            this.iframeError = true;
            return;
        }

        const requestData = {
            accountNumber: this.accountNumber,
            amount: this.totalAmount,
            firstName: this.getApplicant.firstName,
            lastName: this.getApplicant.lastName,
            email: this.getApplicant.emailAddress,
            country: this.getApplicant.country,
            zipCode: this.getApplicant.zipCode
        };

        try {
            const { data } = await axios({
                url: '/api/payment/link',
                method: 'POST',
                data: JSON.stringify(requestData),
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json'
                }
            });

            if (data && data.url) {
                this.iframeSrc = data.url;
            } else {
                this.iframeError = true;
            }
        } catch (e) {
            this.iframeError = true;
        }
    },
    beforeDestroy() {
        window.removeEventListener('message', this.iframeEventHandler);
        // Reset for future visits to this component within the SPA
        this.iframeError = false;
        this.iframeSrc = null;
        this.seenMessage = false;
    },
    methods: {
        submit() {
            const { storageKey } = constants;
            localStorage.removeItem(storageKey);
        },
        back() {
            this.$router.push('/jewelry-details');
        },
        saveAndFinishLater() {
            this.$eventBus.$emit('save');
        },
        async iframeEventHandler(e) {
            const { data: message } = e;
            if (!message) return;

            if (message.startsWith && message.startsWith('PaymentSuccess:')) {
                const paymentId = message.substring('PaymentSuccess:'.length);

                try {
                    const { data: paymentDetail } = await axios({
                        url: '/api/payment/detail',
                        method: 'POST',
                        data: JSON.stringify({
                            accountNumber: this.accountNumber,
                            paymentId
                        }),
                        headers: {
                            'Content-type': 'application/json',
                            Accept: 'application/json'
                        }
                    });
                    await this.submitNewPolicy(this.accountNumber);

                    await this.$store.dispatch(constants.actionTypes.SET_PAYMENT, paymentDetail);
                } catch (e) {
                    await this.$store.dispatch(constants.actionTypes.SET_PAYMENT, {
                        status: 'UPDATE_FAILED'
                    });
                }

                this.$router.push('/confirmation');
            } else if (message.startsWith && message.startsWith('height:')) {
                this.seenMessage = true;

                const height = Number(message.substring('height:'.length));
                if (height > 0) {
                    this.$refs.payIframe.setAttribute('height', height + 51 + 'px');
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.meta && to.meta.inFlow && to.path !== '/') {
            // Prevent the user from going backwards in the flow once they have submitted policy
            next(false);
            return;
        }
        next();
    }
};
</script>

<style lang="scss" scoped>
.pay-iframe-container {
    position: relative;
}

.payment-iframe {
    width: 100%;
    border: 0;
    overflow-y: hidden;
}

.pay-loading-spinner {
    animation: spin 1000ms infinite linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.pay-loading-text {
    font-size: 14px;
    font-weight: bold;
    vertical-align: bottom;
}
</style>
